import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { debounce } from 'lodash';
import i18next, { t } from "i18next";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getAuthToken, getUserDetails } from "../../../components/src/NativeWebRouteWrapper/Utils";
type Color = "success" | "info" | "warning" | "error" | undefined;

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  groupsData: GroupsData | []
  hashtagsData: HashTags;
  postsData: Posts;
  metaData: any;
  filterEndpoint: string;
  commentInput: string;
  topTrendingData: GroupsData | []
  isOpen: boolean;
  postInput: string;
  isAttachOpen: boolean
  isCommentActive: boolean
  commentActiveIndex: number | null
  postMedia: [] | any
  isCommentEnable: boolean
  commentIndex: number | null
  isReportModal: boolean
  reportIndex: number | null
  replyIndex: number | null
  postDetails: PostsDetailsInterface
  postDetailToReplaceData: any
  recentGroupsData: GroupsData | []
  postPageNumber: number
  postPageLimit: number
  isPostLoading: boolean
  postIdToReplace: number | string
  postIndexToReplace: number
  confirmModalOpen: boolean;
  reportPostId: number | string;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  userInterest: any;
  existedInterest: any;
  groupKeys: any;
  openInterest: boolean;
  userPostInterest: []
  modalMediaData: {}
  isModalMedia: boolean
  isReplied: boolean
  repiedIndex: number | null
  allMediaArray: any
  userDetails: userinterface
  isLoading: boolean
  showModalSnackbar: boolean
  postId: number
  existedMedia: any
  editPostId: number | null
  group: any;
  searchKeyword: string;
  searchBar: boolean;
  isSearchType: string;
  userListData: [];
  userSearchProfile: any;
  status: string;
  isEdit: boolean
  mediaIndexes: number[]
  translationPostId: string | number
  translationArray: any
  noFoundSearch: boolean
  hashtagFiltered: any[]
  hashtagId: number | null
  hashValue: string | null
  isHashTag: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export type userinterface = {
  id: number
  activated: boolean,
  country_code: number,
  email: string,
  first_name: string,
  full_phone_number: number,
  last_name: string,
  full_name: string,
  gender: null,
  phone_number: number,
  type: string,
  created_at: null,
  updated_at: null,
  device_id: null,
  unique_auth_id: string,
  photo: string,
  cover_picture: null,
  profession: string,
  city: string,
  state: string,
  country: string,
  partnership_practitioner: null
  accredited_partnership_broker: boolean
  organizational_affilation: null
  preferred_language: string
}

export type GroupsData = {
  id: string,
  type: string,
  attributes: {
    name: string,
    group_admin_id: string,
    group_status: string,
    group_type: string,
    accounts: {
      id: string,
      account_id: number,
      created_at: string,
      updated_at: string,
      group_id: number,
      status: string,
      request_type: string
    }[]
  }
  interests:
  {
    id: number,
    name: string,
    created_by_admin: boolean,
    created_at: string,
    updated_at: string,
    interest_category: string,
    status: string
  }[]
  icon_image: string,
  cover_image: string
}[]

type HashTags = {
  id: string,
  type: string,
  attributes: {
    name: string,
    interest: {
      id: number,
      name: string,
      created_by_admin: boolean,
      created_at: string,
      updated_at: string,
      interest_category: string
      status: string
    }
  }
}[]

type Posts = {
  id: number,
  type: string,
  attributes: {
    id: number,
    author_name: string,
    description: string,
    body: null,
    location: null,
    post_likes: number,
    account_id: number,
    created_at: string,
    updated_at: string,
    model_name: string,
    images: {
      id: number,
      filename: string,
      url: string,
      type: string
    }[],
    videos: [],
    documents: [],
    audios: []
  }
}[]

export type AllCommentAttributes = {

  id: string,
  type: string,
  attributes: {
    id: number,
    author_id: number,
    comment: string,
    author_image: string,
    author_name: string,
    account_id: number,
    created_at: string,
    updated_at: string,
    reply_counts: number | null,
    comments: {
      data: []
    }
  }
}

const userInitialValue = {
  id: 0,
  activated: false,
  country_code: 0,
  email: '',
  first_name: '',
  full_phone_number: 0,
  last_name: '',
  full_name: '',
  gender: null,
  phone_number: 0,
  type: '',
  created_at: null,
  updated_at: null,
  device_id: null,
  unique_auth_id: '',
  photo: '',
  cover_picture: null,
  profession: '',
  city: '',
  state: '',
  country: '',
  partnership_practitioner: null,
  accredited_partnership_broker: false,
  organizational_affilation: null,
  preferred_language: '',
}
type PostsDetailsInterface = {
  id: number,
  type: string,
  attributes: null | {}
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAddress: string = ""
  hashtagsApiAddress: string = ""
  allHashtagsApiAddress: string = ""
  postsApiAddress: string = ""
  translationApiAddress: string = ""
  popularFilterApiAddress: string = ""
  likesApiAddress: string = ""
  commentApiAddress: string = ""
  topTrendingApiAddress: string = ""
  postsDetailsApiAddress: string = ""
  postDetailToReplaceApiAddress: string = ""
  postCreateApiAddress: string = ""
  token = getAuthToken()
  mediaArr: any = []
  apiGetRecentGroup: string = ""
  reportApiAddress: string = ""
  apiGetUserInterest: string = ""
  deleteApiAddress: string = ""
  editApiAddress: string = ""
  joinGroupApiCallId: string = ""
  searchContentApiAddress: string = ""
  findUserApiAddress: string = ""
  attachDeleteApiAdrress: string = ""
  commentDeleteApiAdrress: string = ""
  UnreadNotificationCountApiAddress: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      filterEndpoint: "posts/posts",
      groupsData: [],
      hashtagsData: [],
      postsData: [],
      metaData: {},
      topTrendingData: [],
      commentInput: "",
      isOpen: false,
      postInput: "",
      isAttachOpen: false,
      isCommentActive: false,
      commentActiveIndex: null,
      postMedia: [],
      isCommentEnable: false,
      commentIndex: null,
      isReportModal: false,
      reportIndex: null,
      replyIndex: null,
      postDetails: { id: 0, type: '', attributes: null },
      postDetailToReplaceData: {},
      recentGroupsData: [],
      postPageNumber: 1,
      postPageLimit: 10,
      isPostLoading: false,
      postIdToReplace: "",
      postIndexToReplace: 0,
      confirmModalOpen: false,
      reportPostId: "",
      showSnackbar: false,
      severity: undefined,
      message: "",
      userInterest: [],
      existedInterest: [],
      groupKeys: {},
      openInterest: false,
      userPostInterest: [],
      modalMediaData: {},
      isModalMedia: false,
      isReplied: false,
      repiedIndex: null,
      allMediaArray: [],
      userDetails: userInitialValue,
      isLoading: false,
      showModalSnackbar: false,
      postId: 0,
      existedMedia: [],
      editPostId: null,
      group: {},
      status: "",
      searchKeyword: "",
      searchBar: false,
      isSearchType: "",
      userListData: [],
      userSearchProfile: {},
      isEdit: false,
      mediaIndexes: [],
      translationPostId: '',
      translationArray: [],
      noFoundSearch: false,
      hashtagFiltered: [],
      hashtagId: null,
      hashValue: null,
      isHashTag: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  recentGroupApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiGetRecentGroup) {
      if (responseJson != null) {
        if (responseJson.data) {
          const groups = responseJson.data.map((item: any) => {
            for (let account of item.attributes.accounts) {
              if (account.account_id.toString() === this.state.userDetails.id.toString()) {
                return { ...item, status: account.status, request_type: account.request_type }
              }
            }
            return item
          })
          this.setState({
            recentGroupsData: groups
          });
        }
      }
    }
  }
  apiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiGetAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          const groups = responseJson.data.map((item: any) => {
            for (let account of item.attributes.accounts) {
              if (account.account_id.toString() === this.state.userDetails.id.toString()) {
                return { ...item, status: account.status, request_type: account.request_type }
              }
            }
            return item
          })
          this.setState({
            groupsData: groups
          });
        }
      }
    }
  }
  hashtagsApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.hashtagsApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let hashtagsData = responseJson.data;
          this.setState({
            hashtagsData: hashtagsData
          });
        }
      }
    }
  }
  allHashtagsApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.allHashtagsApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let hashtagsData = responseJson.data.attributes.all_posts.data;
          this.setState({ isHashTag: true, hashtagFiltered: hashtagsData })
        }
      }
    }
  }
  postsDataApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postsApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postsData = responseJson.data;
          let metaData = responseJson.meta;
         
          if (Object.keys(this.state.postDetailToReplaceData).length) {
            let copyData = [...this.state.postsData]
            copyData[this.state.postIndexToReplace] = this.state.postDetailToReplaceData;
            this.setState({ postsData: copyData })
          }
          this.handleState(metaData,postsData);
          
          if (this.state.isHashTag) {
            this.handleHashTags("", this.state.hashtagId)
          }
        } else {
          this.setState({ isPostLoading: false })
        } 
      }
    }
  }

  handleState = (metaData: any, postsData: any) => {
  if(this.state?.metaData?.pagination?.current_page > this.state?.metaData?.pagination?.total_pages ){
    this.setState({
      metaData: metaData,
      noFoundSearch: false,
      isPostLoading: false
    })
  }else{
    setTimeout(() => {
      document.body.style.overflow = "scroll";

     this.setState({
      postsData: [...this.state.postsData, ...postsData],
      metaData: metaData,
      noFoundSearch: false,
      isPostLoading: false
    });
  }, 2000 );
  }
}

  likeApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.likesApiAddress) {
      if (responseJson != null) {
        this.getPostDetailToReplace(this.state.postIdToReplace)
      }
      this.setState({ hashValue: null});
      if(this.state.postPageNumber < this.state?.metaData?.pagination?.total_pages ){
        this.setState({ postPageNumber: this.state.postPageNumber + 1 })
      }
      if (this.state.postDetails?.attributes) {
        this.getPostsDetails(this.state.postId)
      } else if (this.state.searchKeyword !== "") {
        this.getSearchContent(this.state.searchKeyword);
      } else if (this.state.isHashTag === true) {
        this.handleHashTags("", this.state.hashtagId)
      } else if (this.state.isHashTag === false) {
        this.getPosts();
      }
    }
  }
  commentApiResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.commentApiAddress) {
      if (responseJson != null) {
        this.getPostDetailToReplace(this.state.postIdToReplace)
      }
      if(this.state.postPageNumber < this.state?.metaData?.pagination?.total_pages ){
        this.setState({ postPageNumber: this.state.postPageNumber + 1 })
      }
      this.setState({ hashValue: null});
      if (this.state.postDetails?.attributes) {
        this.getPostsDetails(this.state.postId)
      } else if (this.state.searchKeyword !== "") {
        this.getSearchContent(this.state.searchKeyword);
      } else if (this.state.isHashTag === true) {
        this.handleHashTags("", this.state.hashtagId)
      } else if (this.state.isHashTag === false) {
        this.getPosts();
      }
      this.setState({ hashValue: null, commentInput: "" })
    }
  }
  topTrendingApiResponseHanlder = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.topTrendingApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let topTrendingData = responseJson.data;
          this.setState({
            topTrendingData: topTrendingData
          });
        }
      }
    }
  }
  translationApiResponseHanlder = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.translationApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let data = responseJson.data.translations[0].translatedText;
          const newObject = { id: this.state.translationPostId, data: data };
          const checkedIndex = this.state.translationArray.findIndex((i: any) => i.id === newObject.id);

          if (checkedIndex !== -1) {
            const updatedArray = [...this.state.translationArray];
            updatedArray[checkedIndex] = { ...updatedArray[checkedIndex], data: data };
            this.setState({ translationArray: updatedArray });
          } else {
            this.setState({ translationArray: [...this.state.translationArray, newObject] })
          }
        }
      }
    }
  }
  createPostResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postCreateApiAddress) {
      if(this.state.postPageNumber < this.state?.metaData?.pagination?.total_pages ){
        this.setState({ isLoading: false, postPageNumber: 1 });
      }
      this.setState({ postsData: [], allMediaArray: [], postMedia: [] });
      this.getHashtags()
      this.getPosts()
      if (responseJson != null) {
        if (responseJson.data) {
          this.setState({
            isOpen: false,
            showSnackbar: true,
            message: t("Post created successfully"),
            severity: "success",
          });
        }
        else {
          this.setState({
            showModalSnackbar: true,
            message: responseJson?.errors[0] || t('something went wrong'),
            severity: "error",
          });
        }
      }
    }
  }
  postDetailsHanlder = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postsDetailsApiAddress) {
      if (responseJson != null) {
        localStorage.setItem('notify_arr', JSON.stringify({}))
        if (responseJson.data) {
          let postDetailsData = responseJson.data;
          this.setState({
            postDetails: postDetailsData
          });
        }
      }
    }
  }
  postDetailToReplaceHanlder = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postDetailToReplaceApiAddress) {
      if (responseJson != null) {
        if (responseJson.data) {
          let postDetailData = responseJson.data;
          this.setState({
            postDetailToReplaceData: postDetailData
          });
        }
      }
    }
  }
  reportApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.reportApiAddress) {
      this.setState({ reportPostId: "" });
      if (responseJson?.message) {
        this.setState({
          showSnackbar: true,
          message: responseJson.message,
          severity: "success",
        });
      } else {
        this.setState({
          showSnackbar: true,
          message: responseJson?.errors[0] || t('something went wrong'),
          severity: "error",
        });
      }

    }
  }
  userInterestApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiGetUserInterest) {
      if (responseJson != null) {
        if (responseJson.data) {
          let userInterestData = responseJson.data;
          const groupedKeys = userInterestData.reduce((group: { [key: string]: any[] }, item: any) => {
            if (!group[item.attributes.interest_category]) {
              group[item.attributes.interest_category] = [];
            }
            group[item.attributes.interest_category].push(item);
            return group;
          }, {});

          this.setState({
            userInterest: userInterestData,
            groupKeys: groupedKeys
          });
        }
      }
    }
    this.filterInterest()
  }
  notificationUnreadCountapiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.UnreadNotificationCountApiAddress) {
      if (responseJson != null) {
        if (responseJson?.unread_notifications_count) {
          localStorage.setItem("notification_unread_count", responseJson.unread_notifications_count);
        } else {
          localStorage.setItem("notification_unread_count", '');
        }
      }
    }
  }

  filterInterest = () => {
    let arr: Array<{ cat: string; key: string; id: number }> = [];

    Object.keys(this.state.groupKeys).forEach((key: string) => {
      this.state.groupKeys[key].forEach((intrst: { id: number, attributes: { name: string } }) => {
        arr.push({
          cat: key,
          key: intrst.attributes.name,
          id: intrst.id
        });
      });
    });
    this.setState({ userInterest: arr })
    return arr;
  }

  filterInterestEdit = (array: any) => {
    if (array) {
      let arr: any = [];

      array.forEach((intrst: any) => {
        arr.push({
          cat: intrst.interest_category,
          key: intrst.name,
          id: intrst.id
        });
      });

      this.setState({ existedInterest: arr })
      return arr;
    } else {
      return []
    }
  }

  deleteApiHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.deleteApiAddress) {
      if (responseJson) {
        this.setState({
          isOpen: false,
          showSnackbar: true,
          message: responseJson.message,
          severity: "success",
          postDetails: { id: 0, type: '', attributes: null },
        });
      }
    }
  }
  joinGroupApiCallHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.joinGroupApiCallId) {
      if (responseJson) {
        this.setState({
          isOpen: false,
          showSnackbar: true,
          message: responseJson.message,
          severity: "success",
        });
        this.getRecentGroup();
        this.getGroups();
      }
    }
  }

  searchDataApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.searchContentApiAddress) {
      if (responseJson != null) {

        if (responseJson.data) {
          let allSearchData = responseJson.data;
          if (this.state.isSearchType === "user") {
            this.setState({
              userListData: allSearchData.accounts,
              noFoundSearch: false
            });
          } else if (this.state.isSearchType === "post") {
            this.setState({
              postsData: allSearchData.posts,
              noFoundSearch: false
            });
          }
        } else {
          this.setState({ noFoundSearch: true })
        }
      }
    }
  }

  findUserApiCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.findUserApiAddress) {
      if (responseJson != null) {
        this.setState({ isLoading: false });
        if (responseJson.data) {
          let userProfile = responseJson.data;
          this.setState({
            userSearchProfile: userProfile
          });
        }
      }
    }
  }

  editResponseHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.editApiAddress) {
      this.setState({ allMediaArray: [], postMedia: [], mediaIndexes: [] });
      if (responseJson) {
        this.getPostDetailToReplace(this.state.postIdToReplace)
        if(this.state.postPageNumber < this.state?.metaData?.pagination?.total_pages ){
          this.setState({ postPageNumber: this.state.postPageNumber + 1 })
        }
        if (this.state.postDetails?.attributes) {
          this.getPostsDetails(this.state.postId)
        }
        if (responseJson.data) {
          this.getPosts()
          this.setState({
            isOpen: false,
            isLoading: false,
            allMediaArray: [],
            showSnackbar: true,
            message: t("Post edited successfully"),
            severity: "success",
          });
        }
      }
    }
  }

  commentDeleteHandler = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.commentDeleteApiAdrress) {
      if (responseJson != null) {
        this.getPostDetailToReplace(this.state.postIdToReplace)
        if(this.state.postPageNumber < this.state?.metaData?.pagination?.total_pages ){
          this.setState({ postPageNumber: this.state.postPageNumber + 1 })
        }
        this.getPostsDetails(this.state.postId)
        this.getPosts()
        if (responseJson.message) {
          this.setState({
            isLoading: false,
            showSnackbar: true,
            message: responseJson.message,
            severity: "success",
          });
        }
      }
    }
  }

  getUserProfile = (id: number) => {
    this.findUser(id);
  }

  handleSearchTabChange = (val: string) => {
    this.setState({ isSearchType: val, postsData: [] })
    this.getSearchContent(this.state.searchKeyword);
  }

  handleSearchChange = (evt: any) => {

    const { value } = evt.target;
    this.setState({ searchKeyword: value });
    const debouncedSave = debounce((value: any) => {
      if (value !== "") {
        this.setState({ searchBar: true });
      } else {
        this.setState({ searchBar: false, isSearchType: "" });
        this.getPosts();
      }
    }, 100);
    debouncedSave(value);
  }

  noSerachFoundHandle = () => {
    this.setState({ noFoundSearch: false })
  }

  playAudio = () => {
    document.addEventListener('play', function (e) {
      const audios = document.getElementsByTagName('audio');
      for (let i = 0, len = audios.length; i < len; i++) {
        if (audios[i] != e.target) {
          audios[i].pause();
        }
      }
    }, true);

  };

  playVideo = () => {
    document.addEventListener('play', function (e) {
      const videos = document.getElementsByTagName('video');
      for (let i = 0, len = videos.length; i < len; i++) {
        if (videos[i] != e.target) {
          videos[i].pause();
        }
      }
    }, true);

  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };
  handleCloseModalSnackbar = () => {
    this.setState({
      showModalSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  handleConfirmModalClose = () => {
    this.setState({ confirmModalOpen: false });
    this.setState({ reportPostId: "" });
  }
  handleConfirmModalSubmit = () => {
    this.setState({ confirmModalOpen: false });
    this.reportPost(this.state.reportPostId);
  }

  handleConfirmModalOpen = (id: string) => {
    this.setState({ confirmModalOpen: true });
    this.setState({ reportPostId: id });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.apiCaller(apiRequestCallId, responseJson)
      this.hashtagsApiCaller(apiRequestCallId, responseJson)
      this.allHashtagsApiCaller(apiRequestCallId, responseJson)
      this.postsDataApiCaller(apiRequestCallId, responseJson)
      this.likeApiHandler(apiRequestCallId, responseJson)
      this.commentApiResponseHandler(apiRequestCallId, responseJson)
      this.topTrendingApiResponseHanlder(apiRequestCallId, responseJson)
      this.createPostResponseHandler(apiRequestCallId, responseJson)
      this.postDetailsHanlder(apiRequestCallId, responseJson)
      this.recentGroupApiCaller(apiRequestCallId, responseJson);
      this.reportApiHandler(apiRequestCallId, responseJson);
      this.userInterestApiHandler(apiRequestCallId, responseJson);
      this.deleteApiHandler(apiRequestCallId, responseJson);
      this.joinGroupApiCallHandler(apiRequestCallId, responseJson);
      this.searchDataApiCaller(apiRequestCallId, responseJson);
      this.translationApiResponseHanlder(apiRequestCallId, responseJson);
      this.findUserApiCaller(apiRequestCallId, responseJson);
      this.editResponseHandler(apiRequestCallId, responseJson)
      this.commentDeleteHandler(apiRequestCallId, responseJson)
      this.notificationUnreadCountapiCaller(apiRequestCallId, responseJson)
      this.postDetailToReplaceHanlder(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    localStorage.removeItem("edit_group")
    if (window.location.pathname !== "/CommunityForumWeb") {
      const url = window.location.href.split('/')
      const lastPart = url[url.length - 1];
      this.getPostsDetails(Number(lastPart))
    }
    const notifyArr = JSON.parse(localStorage.getItem("notify_arr")!)
    if (notifyArr?.type === "post") {
      this.getPostsDetails(Number(notifyArr.id));
    }
    this.getGroups()
    this.getHashtags()
    this.getPosts()
    this.getNowTrending()
    this.getRecentGroup()
    const localItems: any = getUserDetails();
    this.startCount();
    setInterval(() => {
      this.getUnreadNotification();
    }, 1800000);
    this.setState({ userDetails: localItems });
    const userLang = localItems.preferred_language;
    i18next.changeLanguage(userLang.toLowerCase());
    window.scrollTo(0, 0)
    window.addEventListener("scroll", this.handleScroll);
  }

  startCount = () => {

  }
  handleScroll = () => {
    this.setState({ isPostLoading: true, hashValue: null })
     const scrollElement = document.scrollingElement || document.documentElement;
     const scroll = scrollElement.scrollTop;

    const {  clientHeight, scrollHeight } = document.documentElement;
    
    if ( scroll + clientHeight >= scrollHeight - 100 && this.state.hashtagFiltered.length === 0) {
      if (this.state.postsData.length === 0 || this.state.postPageNumber >= this.state?.metaData?.pagination?.total_pages || this.state.searchKeyword.length > 0) {
        this.setState({ isPostLoading: false })
      } else {
          this.setState({ postPageNumber: this.state.postPageNumber + 1 });
          document.body.style.overflow = "hidden";
          this.getPosts();
      }
    }else{
      this.setState({ isPostLoading: false })
    }
  }

  onFilterChangeHandler = (e: any) => {
    this.setState({ filterEndpoint: e.target.value }, () => this.getPosts())
  }

  handleBackHomeEmpty = () => {
    this.setState({ userSearchProfile: {}, searchKeyword: '', searchBar: false, userListData: [], isSearchType: "" })
  }

  postMediaHandler = (e: any, type: string) => {
    const { name } = e.target
    const multipleItems = e.target.files;
    let multipleItemsArray = [...multipleItems]
    let resdata = this.handlePostFilesValidation(multipleItemsArray, type);
    const finalRes = multipleItemsArray.filter((val: any, indx: number) => (!resdata.includes(indx)));
    this.readFilePhotoPreview(finalRes);
    this.setState({ allMediaArray: [...this.state.allMediaArray, ...finalRes] })
    if (resdata.length > 0) {
      type === "image" &&
        this.setState({
          showModalSnackbar: true,
          message: t("Only png, jpeg and jpg file can be upload"),
          severity: "error",
        });
      type === "video" &&
        this.setState({
          showModalSnackbar: true,
          message: t("Only mp4, mov, wmv, flv, avi, mkv and webm file can be upload"),
          severity: "error",
        });
      type === "audio" &&
        this.setState({
          showModalSnackbar: true,
          message: t("Only mp3 and mpeg file can be upload"),
          severity: "error",
        });
      type === "document" &&
        this.setState({
          showModalSnackbar: true,
          message: t("Only doc, docx, csv, pdf, and msword file can be upload"),
          severity: "error",
        });

    }
  }


  handlePostFilesValidation = (fileState: any, type: string) => {
    let isValid = true;
    let fileArray = [...fileState]
    let removeIndx: any = [];
    const image_extension = ["image/png", "image/jpeg", "image/jpg"];
    const video_extension = ["video/mp4", "video/mov", "video/wmv", "video/flv", "video/avi", "video/mkv", "video/webm"];
    const audio_extension = ["audio/mp3", "audio/mpeg"];
    const doc_extension = ["application/doc", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/csv", "application/pdf", "application/msword"];
    switch (type) {

      case "image":
        fileArray.forEach((file: any, i: number) => {
          if (!image_extension.includes(file.type)) {
            removeIndx.push(i);
          }
        });
        break;

      case "video":
        fileArray.forEach((file: any, i: number) => {
          if (!video_extension.includes(file.type)) {
            removeIndx.push(i);
          }
        });
        break;

      case "audio":
        fileArray.forEach((file: any, i: number) => {
          if (!audio_extension.includes(file.type)) {
            removeIndx.push(i);
          }
        });
        break;

      case "document":
        fileArray.forEach((file: any, i: number) => {
          if (!doc_extension.includes(file.type)) {
            removeIndx.push(i);
          }
        });
        break;

    }
    return removeIndx;
  };

  postInputHandler = (e: any) => {
    this.setState({ postInput: e.target.value })
  }

  postInterestHandler = (selectedList: any) => {
    let filteredList = selectedList.map((i: any) => i.id)
    this.setState({ userPostInterest: filteredList })
  }

  postInterestRemove = (selectedList: any) => {
    let filteredList = selectedList.map((i: any) => i.id)
    this.setState({ userPostInterest: filteredList })
  }

  onShareInputHandler = (text: string) => {
    let url = window.location.origin + `${'/CommunityForumWeb/' + text}`
    navigator.clipboard.writeText(url);
    this.setState({
      showSnackbar: true,
      message: "Copied!",
      severity: "success",
    });
  }

  onCommentChange = (e: any) => {
    this.setState({ isCommentActive: true })
    this.setState({ commentInput: e.target.value })
  }

  postModalHandler = () => {
    this.setState({ isEdit: false, isOpen: !this.state.isOpen, isAttachOpen: false, userPostInterest: [], existedInterest: [] })
    this.setState({ postIndexToReplace: 0, postIdToReplace: '' })
    this.setState({ postMedia: [] })
    this.setState({ postInput: '' })
    this.setState({ allMediaArray: [] })
    this.setState({ mediaIndexes: [] })
    this.setState({ showModalSnackbar: false })
    this.getUserInterest();
  }

  onFilteredHandler = (idx: number) => {
    const filteredMedia = this.state.allMediaArray.filter((i: any, id: number) => id !== idx)
    this.setState({ allMediaArray: filteredMedia })
    const shownMediaFilter = this.state.postMedia.filter((i: any, id: number) => id !== idx);
    this.setState({ postMedia: shownMediaFilter })
  }

  handleAttachModal = () => {
    this.setState({ isAttachOpen: !this.state.isAttachOpen })
  }

  handlePostDetails = (id: number) => {
    this.setState({ postId: id })
    this.getPostsDetails(id);
    this.setState({ hashtagFiltered: [], hashValue: null, isHashTag: false })
  }

  handlePostDetailsBack = () => {
    if (window.location.pathname !== "/CommunityForumWeb") {
      window.location.href = "/CommunityForumWeb"
    }
    this.setState({ postDetails: { id: 0, type: '', attributes: null }, hashtagFiltered: [], isHashTag: false, hashValue: null })
    if (this.state.searchKeyword !== "") {
      this.getSearchContent(this.state.searchKeyword);
    }
    // else {
    //   this.getPosts();
    // }
  }

  handleCloseAttachmentModal = () => {
    if (this.state.isAttachOpen === true) {
      this.setState({ isAttachOpen: false })
    }
  }

  onClickCommentIndex = (index: number) => {
    this.setState({ commentActiveIndex: index })
    this.setState({ replyIndex: null })

  }

  onClickReplyComment = (index: number) => {
    this.setState({ commentActiveIndex: index })
    this.setState({ isCommentEnable: false })
  }

  readFilePhotoPreview = (FileList: any) => {
    let fileArray = [...FileList]
    let tempMediaArray: any = [];

    fileArray.forEach((file) => {
      const reader = new FileReader();
      const promise = new Promise((resolve) => {
        reader.onload = (event) => {
          resolve(event.target?.result);
        };
      });

      reader.readAsDataURL(file);
      tempMediaArray.push(promise);
    });

    Promise.all(tempMediaArray).then((results) => {
      this.setState({ postMedia: [...this.state.postMedia, ...results] });
    });
  };

  commentBoxVisibilityHandler = (index: number) => {
    this.setState({ commentInput: "" })
    this.setState({ commentIndex: index })
    this.setState({ isCommentEnable: true })
  }

  onReportModalClickHandler = (index: number) => {
    this.setState({ reportIndex: index })
    this.setState({ isReportModal: !this.state.isReportModal })
  }

  onReportModalCloseHandler = () => {
    if (this.state.isReportModal === true) {
      this.setState({ isReportModal: false })
    }
  }

  onReplyHandler = (index: number, idx: number) => {
    this.setState({ commentInput: "" })
    this.setState({ isCommentEnable: false })
    this.setState({ commentIndex: index })
    this.setState({ replyIndex: idx })
  }

  onRepliedHandler = (index: number, idx: number) => {
    this.setState({ commentIndex: index })
    this.setState({ repiedIndex: idx })
    this.setState({ isReplied: true })
  }

  onMediaModalHandler = (item: any) => {
    this.setState({ isModalMedia: true })
    this.setState({ modalMediaData: item })
  }

  onMediaModalCloseHandler = () => {
    this.setState({ isModalMedia: false })
  }

  handleGroupDetails = (groupData: any) => {
    localStorage.setItem('new_group', JSON.stringify(groupData))
    window.location.href = "AccountGroups";
  }

  groupStatus1 = (item: any) => {
    if (item.status !== "accepted" && item.request_type !== "requested") {
      return t("Join Group")
    } else if (item.status === "pending" && item.request_type === "requested") {
      return t("Requested")
    } else {
      return t("Joined")
    }
  }

  handleGroupRedirection = (type: string) => {
    if (type === "create_group") {
      window.location.href = "CreateNewGroup";
    } else {
      window.location.href = "ViewAllGroups";
    }
  }

  allDownloadHandler = (mediaArr: any) => {
    mediaArr.forEach((media: any, index: number) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', media.url, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        const blob = new Blob([xhr.response], { type: media.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = media.filename || 'downloaded-image';
        link.click();
      };
      xhr.send();
    })
  }

  handleTransBackToOriginal = (id: number | string) => {
    let filteredData = this.state.translationArray.filter((i: any) => i.id !== id)
    this.setState({ translationArray: filteredData })
  }

  onEditHandler = (post: any, index: number) => {
    this.getUserInterest();
    this.setState({ postIndexToReplace: index, postIdToReplace: post.id })
    this.filterInterestEdit(post.attributes.post_interest)
    this.setState({
      isEdit: true,
      postMedia: [],
      editPostId: post.id,
      postInput: post.attributes.description,
      existedMedia: [...post.attributes.images, ...post.attributes.videos, ...post.attributes.audios, ...post.attributes.documents],
      isOpen: !this.state.isOpen,
      showModalSnackbar: false
    })
  }
  onExistedMediaHandler = (itemId: number, idx: number) => {
    this.setState({ mediaIndexes: [...this.state.mediaIndexes, itemId] })
    let filteredMedia = this.state.existedMedia.filter((i: any, id: number) => id !== idx)
    this.setState({ existedMedia: filteredMedia })
  }

  onEditApiHandler = () => {
    this.setState({ isLoading: true });
    this.onAttachDeleteHandler()
    let formData: any = new FormData();
    formData.append('data[attributes][description]', this.state.postInput)
    this.state.userPostInterest.map((i) => {
      formData.append('data[attributes][interest_ids][]', i)
    })
    this.state.allMediaArray.map((item: any, idx: number) => {
      this.state.allMediaArray[idx].type.includes('video') && formData.append("data[attributes][new_videos][]", this.state.allMediaArray[idx]);
      this.state.allMediaArray[idx].type.includes('image') && formData.append("data[attributes][new_images][]", this.state.allMediaArray[idx]);
      this.state.allMediaArray[idx].type.includes('application') && formData.append("data[attributes][new_documents][]", this.state.allMediaArray[idx]);
      this.state.allMediaArray[idx].type.includes('audio') && formData.append("data[attributes][new_audios][]", this.state.allMediaArray[idx]);
    })

    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPostApiEndPoint}${'/' + this.state.editPostId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleHashTags = (tags: string, id: number | null) => {
    this.setState({ hashtagId: id })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allHashtagsApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hashtagsAPiEndPoint}${'/' + id}/get_posts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleTranslation = (description: string, id: number | string) => {
    this.setState({ translationPostId: id })
    const encodedDescription = encodeURIComponent(description);
    const { preferred_language: language } = this.state.userDetails;

    const languageCodeMap: { [key: string]: string } = {
      English: 'en',
      French: 'fr',
      Bengali: 'bn',
    };
    const transLanguage = languageCodeMap[language] || 'en';

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.translationApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.translationApiEndPoint}?q=${encodedDescription}&target=${transLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onCommentDeleteHandler = (commentId: number, postId: number | string, index: number) => {
    this.setState({ isLoading: true, postIdToReplace: postId, postIndexToReplace: index })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentDeleteApiAdrress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentApiEndPoint}${'/' + commentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onAttachDeleteHandler = () => {
    const body = {
      attachment_id: this.state.mediaIndexes
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.attachDeleteApiAdrress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeAttachmentEndPoint}${'/' + this.state.editPostId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onDeleteHandler = (postId: number) => {
    this.getHashtags()
    const deletedPosts = this.state.postsData.filter((item: any) => item.attributes.id !== postId)
    this.setState({ postsData: deletedPosts, hashtagFiltered: [], hashValue: null, isHashTag: false })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPostApiEndPoint}${'/' + postId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onCommentHandler = (id: number, commentId: number | null, index: number) => {
    this.setState({ isCommentActive: false, postIdToReplace: id, postIndexToReplace: index })
    const validId = commentId ? commentId : id
    const type = commentId ? "BxBlockComments::Comment" : "BxBlockPosts::Post"
    const body = {
      data: {
        attributes: {
          comment: this.state.commentInput,
          commentable_id: validId,
          commentable_type: type
        }
      }
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ replyIndex: null })
    return true;
  }

  onLikeHandler = (id: number, isLike: boolean, likeId: any, index: number) => {
    this.setState({ postIndexToReplace: index })
    this.setState({ postIdToReplace: id })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    if (isLike) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.likesApiAddress = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.likesApiEndPoint}${'/' + likeId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteAPiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.PostLikeHandler(id)
    }
  }

  PostLikeHandler = (id: number) => {
    const body = {
      data: {
        attributes: {
          likeable_id: id,
          likeable_type: "BxBlockPosts::Post"
        }
      }
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likesApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.likesApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getGroups = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getHashtags = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hashtagsApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hashtagsAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPosts = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postsApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${this.state.filterEndpoint}?[data][attributes][page]=${this.state.postPageNumber}&[data][attributes][per_page]=${this.state.postPageLimit}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getNowTrending = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.topTrendingApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.topTrendingApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  postApiHandler = () => {
    this.setState({ isLoading: true });
    let formData: any = new FormData();
    formData.append('data[attributes][description]', this.state.postInput);
    this.state.userPostInterest.map((i) => {
      formData.append('data[attributes][interest_ids][]', i)
    })
    for (let i = 0; i < this.state.allMediaArray.length; i++) {
      this.state.allMediaArray[i].type.includes("image") && formData.append('data[attributes][images][]', this.state.allMediaArray[i]);
      this.state.allMediaArray[i].type.includes("video") && formData.append('data[attributes][videos][]', this.state.allMediaArray[i]);
      this.state.allMediaArray[i].type.includes("audio") && formData.append('data[attributes][audios][]', this.state.allMediaArray[i]);
      this.state.allMediaArray[i].type.includes("application") && formData.append('data[attributes][documents][]', this.state.allMediaArray[i]);
    }

    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCreateApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPostApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostsDetails = (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postsDetailsApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPostApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostDetailToReplace = (id: number | string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postDetailToReplaceApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPostApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getRecentGroup = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetRecentGroup = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.recentGroupsAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  reportPost = (id: number | string) => {

    const data = {
      post_id: id
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserInterest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetUserInterest = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userInterestAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleGroupJoin = (group: any) => {
    this.setState({ group, status: "requested" })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.joinGroupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      group.attributes && group.attributes.group_type === "public" ? `account_groups/join_public_group/${group.id}` : `account_groups/send_request/${group.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSearchContent = (searchKey: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchContentApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchAPiEndPoint}?[data][attributes][query]=${searchKey}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  findUser = (id: number) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.findUserApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.findUserApiEndPoint + id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUnreadNotification = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UnreadNotificationCountApiAddress = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unreadNotificationCountApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
