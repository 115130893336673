import React from "react";
// Customizable Area Start
import {Button, Typography, Box,Paper } from '@material-ui/core';
import './SideBar.scss';
import { SideBarScreenNames } from "../../blocks/user-profile-basic/src/UserProfileBasicController";
import {t} from "i18next";
// Customizable Area End

import UserProfileBasicController, { Props } from "../../blocks/user-profile-basic/src/UserProfileBasicController";

export default class SideBar extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    let browserContent = (
      <Box
        style={{
          backgroundColor: '#fff',
          padding: '16px',
          borderRadius: '8px'
        }} 
        flex={1} p={2}>
        <Box>
          <Button
            style={{
              borderBottom: '1px solid #ccc',
              paddingBottom: '9px',
              paddingTop: '0px',
              width: '100%',
              cursor: 'pointer',
              textAlign: 'left',
              borderRadius: "0px",
              justifyContent: 'left',
              paddingLeft: "0px",

            }}
            onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.aboutMe)}
          >
            <Typography className={`hoverable ${this.props.id === SideBarScreenNames.aboutMe && "active-sidebar"}`} style={{paddingTop:'6px', lineHeight: '24px', height: '24px', color: '#475569', fontFamily: 'Comfortaa-Bold', fontSize: '16px', fontWeight: 400 }}>
              {t('About me')}
            </Typography>
          </Button>
          <Button  
            onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.postAboutMe)}
            style={{
              borderBottom: '1px solid #ccc',
              paddingBottom: '12px',
              paddingTop: '12px',
              width: '100%',
              cursor: 'pointer',
              textAlign: 'left',
              justifyContent: 'left',
              paddingLeft: "0px",
              borderRadius:'0px',
            }}>
            <Typography 
            className={`hoverable ${this.props.id === SideBarScreenNames.postAboutMe && "active-sidebar"}`}
              style={{lineHeight: '24px',paddingTop:'6px', height: '24px', color: '#475569', fontFamily: 'Comfortaa-Bold', fontSize: '16px', fontWeight: 400 }}
            >
              {t('Post about me')}
            </Typography>
          </Button>
          <Button
            style={{
              borderBottom: '1px solid #ccc',
              paddingBottom: '12px',
              paddingTop: '12px',
              width: '100%',
              cursor: 'pointer',
              textAlign: 'left',
              borderRadius: "0px",
              justifyContent: 'left',
              paddingLeft: "0px",

            }}
            onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.media)}
          >
            <Typography className={`hoverable ${this.props.id === SideBarScreenNames.media && "active-sidebar"}`}
              style={{
                color: '#475569',
                fontFamily: 'Comfortaa-Bold',
                fontSize: '16px',
                fontWeight: 400,
                paddingTop:'6px',
              }}>
              {t('Media')}
            </Typography>
          </Button>
          <Button
            style={{
              borderBottom: '1px solid #ccc',
              paddingBottom: '12px',
              paddingTop: '12px',
              width: '100%',
              cursor: 'pointer',
              textAlign: 'left',
              borderRadius: "0px",
              justifyContent: 'left',
              paddingLeft: "0px",



            }}
            onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.groups)}
          >
            <Typography className={`hoverable ${this.props.id === SideBarScreenNames.groups && "active-sidebar"}`} 
            style={{ 
              paddingTop:'6px',
            color: '#475569', 
            fontFamily: 'Comfortaa-Bold', 
            fontSize: '16px', 
            fontWeight: 400 ,
            paddingLeft: "0px",

            
            }}>
              {t('Groups')}
            </Typography>
          </Button>
          <Button
            style={{
              paddingTop: '12px',
              width: '100%',
              cursor: 'pointer',
              textAlign: 'left',
              borderRadius: "0px",
              justifyContent: 'left',
              paddingLeft: "0px",
              borderBottom: '1px solid #ccc',


            }}
            onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.recentActivities)}
          >
            <Typography className={`hoverable ${this.props.id === SideBarScreenNames.recentActivities && "active-sidebar"}`} style={{ paddingTop:'6px',color: '#475569', fontFamily: 'Comfortaa-Bold', fontSize: '16px', fontWeight: 400 }}>
            {t('Recent Activities')}
            </Typography>
          </Button>
          <Button
            style={{
              paddingTop: '12px',
              width: '100%',
              cursor: 'pointer',
              textAlign: 'left',
              borderRadius: "0px",
              justifyContent: 'left',
              paddingLeft: "0px",
            }}
            onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.privateMessage)}
          >
            <Typography className={`hoverable ${this.props.id === SideBarScreenNames.privateMessage && "active-sidebar"}`} style={{ paddingTop:'6px',color: '#475569', fontFamily: 'Comfortaa-Bold', fontSize: '16px', fontWeight: 400 }}>
            {t('Private Message')}
            </Typography>
          </Button>
        </Box>
      </Box>
    );

    let mobileContent = (
      <Box
        style={{
          display: 'flex',
          padding: '0px',
          overflowX: "auto",
          borderRadius: '8px',
          flexDirection: 'row',
          // flexWrap: 'wrap',
          margin: '0px',
          backgroundColor: "#F8FAFC",
        }}
        className="mobile-sidebar"
        flex={1}
        p={2}
       >
        <Button className="hoverable-responsive"
          style={{
            cursor: 'pointer',
            justifyContent: 'center',
            backgroundColor: 'rgb(239 239 239)',
            margin: '3px 3px',
            height:"40px",
            textAlign: 'center',
            borderRadius: '6px',
            background: this.props.id === SideBarScreenNames.aboutMe ? "#5497FF":"#F8FAFC",
            // flex: '1',
            width: "max-content",
          }}
          onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.aboutMe)}
        >
          <Typography className="button-text"
            style={{
              fontSize: '14px',
              lineHeight: '22px',
              color: this.props.id === SideBarScreenNames.aboutMe ? "#FFFFFF":"#64748B",
              height: 'auto', 
              fontFamily: 'Comfortaa-Bold',
              fontWeight: 400,
              wordBreak: 'break-word', 
              width: "max-content",
            }}
          >
            {t('About me')}
          </Typography>
        </Button>
        <Button  className="hoverable-responsive"
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            justifyContent: 'center',
            borderRadius: '6px',
            margin: '3px 3px',
            height:"40px",
            background: this.props.id === SideBarScreenNames.postAboutMe ? "#5497FF":"#F8FAFC",
            width: "max-content",
          }}
          onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.postAboutMe)}

        >
          <Typography
           className="button-text"
            style={{
              height: 'auto', 
              color: this.props.id === SideBarScreenNames.postAboutMe ? "#FFFFFF":"#64748B",
              fontFamily: 'Comfortaa-Bold',
              lineHeight: '22px',
              wordBreak: 'break-word', 
              fontWeight: 400,
              fontSize: '14px',
              width: "max-content",
            }}
          >
            {t('Post About Me')}
          </Typography>
        </Button>
        <Button className="hoverable-responsive"
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: 'rgb(239 239 239)',
            margin: '3px 3px',
            height:"40px",
            borderRadius: '6px',
            // flex: '1',
            width: "max-content",
            background: this.props.id === SideBarScreenNames.groups ? "#5497FF":"#F8FAFC",
            transition: 'background-color 0.3s ease',

          }}
          onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.groups)}

        >
          <Typography
            className="button-text"
            style={{
              lineHeight: '22px',
              fontWeight: 400,
              color: this.props.id === SideBarScreenNames.groups ? "#FFFFFF":"#64748B",
              fontFamily: 'Comfortaa-Bold',
              height: 'auto',
              fontSize: '14px',
              wordBreak: 'break-word',
              width: "max-content",
            }}
          >
            {t('Group')}
          </Typography>
        </Button>
        <Button  className="hoverable-responsive"
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgb(239 239 239)',
            cursor: 'pointer',
            borderRadius: '6px',
            // flex: '1',
            width: "max-content",
            margin: '3px 3px',
            height:"40px",
            background: this.props.id === SideBarScreenNames.media ? "#5497FF":"#F8FAFC",

          }}
          onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.media)}

        >
          <Typography
            className="button-text"
            style={{
              height: 'auto',
              fontFamily: 'Comfortaa-Bold',
              lineHeight: '22px',
              color: this.props.id === SideBarScreenNames.media ? "#FFFFFF":"#64748B",
              fontWeight: 400,
              fontSize: '14px',
              wordBreak: 'break-word', 
              width: "max-content",
            }}
          >
            {t('Media')}
          </Typography>
        </Button>
        <Button  className="hoverable-responsive"
          style={{
            cursor: 'pointer',
            flex: '1',
            justifyContent: 'center',
            backgroundColor: 'rgb(239 239 239)',
            textAlign: 'center',
            borderRadius: '6px',
            margin: '3px 3px',
            height:"40px",
            width: "max-content",
            color: this.props.id === SideBarScreenNames.recentActivities ? "#FFFFF":"black", 
            background: this.props.id === SideBarScreenNames.recentActivities ? "#5497FF":"#F8FAFC",

          }}
          onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.recentActivities)}

        >
          <Typography
            className="button-text"
            style={{
              height: 'auto', 
              color: this.props.id === SideBarScreenNames.recentActivities ? "#FFFFFF":"#64748B",
              fontFamily: 'Comfortaa-Bold',
              wordBreak: 'break-word', 
              lineHeight: '24px',
              fontSize: '16px',
              fontWeight: 400,
              width: "max-content",
            }}
          >
            {t('Recent Activities')}
          </Typography>
        </Button>

        <Button  className="hoverable-responsive"
          style={{
            cursor: 'pointer',
            flex: '1',
            justifyContent: 'center',
            backgroundColor: 'rgb(239 239 239)',
            textAlign: 'center',
            borderRadius: '6px',
            margin: '3px 3px',
            height:"40px",
            width: "max-content",
            color: this.props.id === SideBarScreenNames.privateMessage ? "#FFFFF":"black", 
            background: this.props.id === SideBarScreenNames.privateMessage ? "#5497FF":"#F8FAFC",

          }}
          onClick={() => this.props.onSideBarNavigate(SideBarScreenNames.privateMessage)}

        >
          <Typography
            className="button-text"
            style={{
              height: 'auto', 
              color: this.props.id === SideBarScreenNames.privateMessage ? "#FFFFFF":"#64748B",
              fontFamily: 'Comfortaa-Bold',
              wordBreak: 'break-word', 
              lineHeight: '24px',
              fontSize: '16px',
              fontWeight: 400,
              width: "max-content",
            }}
          >
            {t('Private Message')}
          </Typography>
        </Button>
      </Box>


    );
    // Customizable Area End
    return (
      //Required for all blocks
      <>
        {/* Required for all blocks */}

        {/* Customizable Area Start */}
        {/* Merge Engine UI Engine Code */}
        <div className="mobile-view">
          {mobileContent}
        </div>
        <Paper style={{ borderRadius: '8px' }} elevation={1} >
        <div className="header-section">  
          {browserContent}
        </div>
        </Paper>
        {/* Customizable Area End */}
        {/* Merge Engine UI Engine Code */}
      </>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

}
// Customizable Area Start

// Customizable Area End
