import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  Paper,
  Badge,
  Avatar,
  // Customizable Area End
  IconButton,
} from "@material-ui/core";

// Customizable Area Start
import "../assets/membersdirectory.scss"
import Header from "../../../components/src/Header.web";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SearchIcon from '@material-ui/icons/Search';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import { user4 } from "./assets";
import { t } from "i18next";
import Dropdown from "./components/Dropdown";
import MemberDirectoryList from "./components/MemberDirectoryList";
// Customizable Area End

import MemberDirectoryController, {
  Props,
  configJSON,
} from "./MemberDirectoryController";

export default class MemberDirectory extends MemberDirectoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Header type={"MembersDirectory"} navigation={this.props.navigation} />
        <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
          <Grid container className="member_directory_page">
            <Grid container spacing={4} style={{ maxWidth: '100%', width: '100%' }}>
              <Grid item xs={12} md={9} lg={9} xl={9} sm={12}>
                <Grid component={Paper} style={{ borderRadius: '8px' }}>
                  <Paper elevation={3} className="members_directory_list" >
                    <Box>
                      <Box className="md_header_box">
                        <Grid>
                          <Typography className="md_header">{t('Member Directory')}</Typography>
                          <Typography className="md_sub_header">{t('Dedicated to the community growth')}</Typography>
                        </Grid>
                        <Grid container className="members_count_row">
                          <Grid item className="members_count_block" xs={11} sm={11} >
                            <Badge>
                              <PeopleAltIcon
                                style={{
                                  color: '#5497FF',
                                  fontSize: 18
                                }}></PeopleAltIcon>
                            </Badge>
                            <Typography
                              variant="subtitle2"
                              className="member_count"
                            >{t(this.state.membersData.meta.total_count)} {t("Members")}</Typography>
                          </Grid>
                          <Grid item container className="search_icon_grid" xs={1} sm={1}>
                            <Badge>
                              <SearchIcon
                                style={{
                                  color: '#475569',
                                  fontSize: 24
                                }}></SearchIcon>
                            </Badge>
                          </Grid>
                        </Grid>
                      </Box>
                      <MemberDirectoryList membersData={this.state.membersData.data} navigation={this.props.navigation} />
                    </Box>
                  </Paper>
                </Grid>

              </Grid>
              <Grid item xs={12} md={3} lg={3} xl={3} sm={12}>
                <Paper elevation={3} className="members_filter_box" >
                  <Box>
                    <Grid container className="filter_clear_grid">
                      <Grid style={{ display: 'flex' }}>
                        <FilterListIcon style={{
                          color: '#475569',
                          fontSize: "24px",
                        }} />
                        <Typography variant="h6" className="filter_text">
                          {t('Filter')}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Button
                          variant="text"
                          className="clear_all_text"
                          onClick={this.clearAllFilters}
                          data-testid='clear_All_Button'
                        >
                          {t('Clear All')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Dropdown
                      data-test-id="language-dropdown"
                      heading='Working Language'
                      placeholderText="Language"
                      list={[
                        'English', 'Hindi', 'Marathi'
                      ]}
                      selectedState={
                        this.setLanguage
                      }
                      clearSelection={this.state.clearFilters} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setCountry} heading='Country' placeholderText="Country" list={[
                      'India', 'USA', 'New Zealand', 'Japan', 'Canada', 'Australia',
                      'Germany', 'France', 'Italy', 'Spain',
                    ]} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setGivenState} heading='State' placeholderText="State" list={[
                      'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan'
                    ]} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setCity} heading='City' placeholderText="City" list={['Mumbai', 'Pune', 'Nagpur', 'Nashik', 'Thane', 'Aurangabad', 'Solapur', 'Kolhapur', 'Sangli', 'Jalna', 'Akola', 'Amravati', 'Bhiwandi', 'Ulhasnagar', 'Kalyan', 'Dhule', 'Nanded'
                    ]} />
                    <Dropdown clearSelection={this.state.clearFilters} selectedState={this.setRegion} heading='Region' placeholderText="Region" list={['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Northwest Territories', 'Nunavut', 'Yukon'
                    ]} />
                  </Box>
                </Paper>
              </Grid>

            </Grid>
          </Grid>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
