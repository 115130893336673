// Customizable Area Start
import React, { useState, useRef, useEffect } from "react";
import { Button, Divider, Grid, Box, Typography, InputBase, Avatar, Container } from "@material-ui/core";
import { t } from "i18next";
import { user4 } from '../assets'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
interface DropdownProps {
    heading: string;
    list: string[];
    placeholderText: string;
    selectedState: Function;
    clearSelection: boolean;
}

const Dropdown = (props: DropdownProps) => {
    const [dropdownState, setDropdownState] = useState(false)
    const [searchText, setSearchText] = useState('')

    const getFilteredCountries = () => {
        return props.list.filter(country => country.toLowerCase().includes(searchText.toLowerCase()));
    };
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownState(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(()=>{
            setSearchText('')
    },[props.clearSelection, props.selectedState])
    return (
        <Grid className="dropdown_set" data-test-id='dropdown-wrapper-box'>
            <Typography className='dd_headers'>
                {t(props.heading)}
            </Typography>
            <div
                ref={dropdownRef}
                className={dropdownState ? "dd_box" : ""}>
                <Box data-test-id="dropdown-container" className={dropdownState ? "dd_container_open" : "dd_container"} onClick={() => {
                    setDropdownState(!dropdownState)
                }}>
                    <InputBase
                        className="dd_input"
                        placeholder={dropdownState ? "" : props.placeholderText}
                        onChange={(event: any) => {
                            setSearchText(event.target.value)
                        }}
                        value={searchText}
                    />
                    {dropdownState ? <ExpandLessIcon
                        className='dd_icon'
                    /> : <ExpandMoreIcon
                        className='dd_icon'
                    />}
                </Box>
                {dropdownState && <Box className='dropdown'>
                    {getFilteredCountries().map((country: string) => (
                        <Box
                            key={country}
                            onClick={() => {
                                setSearchText(country)
                                setDropdownState(false)
                                props.selectedState(country)
                            }}
                            className='option'
                        >
                            <Typography className='dd_input'>{country}</Typography>
                        </Box>
                    ))}
                </Box>}
            </div>
        </Grid>
    );
}
// Customizable Area End
export default Dropdown;
