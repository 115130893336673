// Customizable Area Start
import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { t } from "i18next";

interface HobbyObject {
    id: number
    name: string
    created_by_admin: boolean
    created_at: string
    updated_at: string
    interest_category: string
    status: string
}
interface HobbyDetailsDataInterface {
    title: string
    hobby: HobbyObject[]
}

const UserSkills = (hobbyDetails: HobbyDetailsDataInterface) => {
    const [showAll, setShowAll] = useState(true);
    const displayedHobbies = showAll ? hobbyDetails.hobby : hobbyDetails.hobby.slice(0, 3);
    const remainingCount = hobbyDetails.hobby.length - displayedHobbies.length;

    return (
        <>
            <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0' }}><Grid item xs={12} sm={6} className="text_first_tb_grid">
                <Typography className="text_first_tb">{t(hobbyDetails.title)}</Typography>
            </Grid>
                {!!hobbyDetails && hobbyDetails.hobby.length > 0 && (<Grid container item xs={12} sm={6} className="skils_second_tb_grid">
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                    }}>
                        {displayedHobbies.map((item: HobbyObject) => {
                            return (
                                <div style={{ marginInline: "5px", width: "max-content" }}>
                                    <Button className="text_button_main"
                                        variant="contained">
                                        <div className="tag-interest">{item.name}</div>
                                    </Button>
                                </div>)
                        })}
                        {remainingCount > 0 && !showAll &&
                            <Button data-test-id="more_button" variant="text" onClick={() => setShowAll(true)}
                                className="md_show_less">{t('+')}{remainingCount} {t('more')}
                            </Button>}
                        {showAll && hobbyDetails.hobby.length > 3 &&
                            <Button data-test-id="show-less-button" variant="text" onClick={() => setShowAll(false)}
                                className="md_show_less">{t('Show Less')}
                            </Button>}
                    </div>
                </Grid>)}
            </Grid>
        </>
    )
}

export default UserSkills;
// Customizable Area End