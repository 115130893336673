import React, { useState } from "react";
import {
    Paper,
    ListItemText,
    Box,
    Grid,
    FormControl,
    FormLabel,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { styled } from '@material-ui/styles';
import { Divider } from "react-native-elements";
import { t } from "i18next";
import { showError } from "./ShowError.web";
import CustomTextFieldComp from "./CustomTextFieldComp.web";

export const CustomPaperB = styled(Paper)(() => ({
    borderRadius: '0 0 8px 8px',
    borderTop: 'none',
    overflowY: 'auto',
    backgroundColor: '#fff',
    margin: 0,
    width: '100%',
    borderLeft: '1px solid #CBD5E1',
    borderRight: '1px solid #CBD5E1',
    borderBottom: '1px solid #CBD5E1',
    scrollbarWidth: 'none',
    '& .MuiAutocomplete-listbox': {
        padding: 0,
        margin: '0px 10px 0px 0px',
        maxHeight: '20vh',
        '&::-webkit-scrollbar': {
            width: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CFCFCF',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-tracks': {
            backgroundColor: '#F1F5F9',
            borderRadius: '4px',
        },
    }
}));
interface OptionsType {
    attributes: Attributes
    id: string
    type: string
}

interface Attributes {
    name: string
    alpha2: string
    alpha3: string
    country_code: string
}
interface InterfaceProps {
    options: OptionsType[]
    title: string;
    initialValue: any;
    onChange: Function;
    fieldName: any
    profileErrorField?: any
    errorKey?: string
}

const CountryAutoComplete = (props: InterfaceProps) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Grid item xs={12} lg={6} sm={12} md={6}>
                <FormControl fullWidth>
                    <FormLabel className="dropdown_title">
                        {t(`${props.title}`)}
                    </FormLabel>
                    <div>
                        <Autocomplete
                            id="combo-box-demo"
                            data-test-id='country-custom-auto-complete'
                            options={props.options}
                            getOptionLabel={(option) => {
                                return option.attributes.name
                            }}
                            style={{
                                borderColor: '#475569'
                            }}
                            onChange={(event: any, value: any) => props.onChange(event, value, props.fieldName)}
                            open={open}
                            value={props.initialValue}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            popupIcon={<ExpandMoreIcon />}
                            disablePortal
                            blurOnSelect
                            PaperComponent={({ children }) => {
                                return (
                                    <CustomPaperB >
                                        <Divider style={{
                                            marginTop: "4px",
                                            marginBottom: "12px",
                                            marginLeft: "12px",
                                            marginRight: "12px"
                                        }} />
                                        {children}
                                    </CustomPaperB>
                                );
                            }}
                            renderOption={(data) => {
                                return (<Box>
                                    <ListItemText
                                        primary={data.attributes.name}
                                        className="dropdown_options"
                                    />
                                </Box>)
                            }
                            }
                            renderInput={(params) =>
                                <CustomTextFieldComp params={params} open={open} />}
                        />
                    </div>
                    {showError({
                        errorKey: props.errorKey,
                        profileErrorField: props.profileErrorField
                    })}
                </FormControl>
            </Grid>
        </>

    );
};

export default CountryAutoComplete;

