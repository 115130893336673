import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface UserData {
  data: {
      id: string;
      type: string;
      attributes: {
          activated: boolean;
          country_code: string | null;
          email: string;
          first_name: string | null;
          full_phone_number: string;
          last_name: string | null;
          full_name: string;
          gender: string | null;
          phone_number: string | null;
          type: string;
          created_at: string;
          updated_at: string;
          device_id: string | null;
          unique_auth_id: string;
          user_interests: {
              id: number;
              interest_id: number;
              account_id: number;
              created_at: string;
              updated_at: string;
          }[];
          interest_name: {
              id: number;
              name: string;
              created_by_admin: boolean;
              created_at: string;
              updated_at: string;
              interest_category: string;
              status: string;
          }[];
          photo: string;
          cover_picture: string;
          profession: string | null;
          city: string | null;
          state: string | null;
          country: string;
          partnership_practitioner: boolean | null;
          accredited_partnership_broker: boolean;
          organizational_affilation: string | null;
          preferred_language: string;
          skill_name: {
              id: number;
              name: string;
              created_by_admin: boolean;
              created_at: string;
              updated_at: string;
              interest_category: string;
              status: string;
          }[];
          experience: string | null;
          last_login_at: string | null;
          connections: any[]; // Specify further if connections have a structure
          about_yourself: string | null;
          time_zone: string | null;
          working_language: string | null;
          number_visible: boolean;
      };
  }[];
  meta: {
        total_count: number,
        current_page: number,
        per_page: number,
        total_pages: number
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedLanguage: string;
  selectedCountry: string;
  selectedState: string;
  selectedCity: string;
  selectedRegion: string;
  clearFilters: boolean;
  membersData: UserData
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MemberDirectoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  dropdownRef: any;
  // Customizable Area Start
  getMemberInfo: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedLanguage: "",
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      selectedRegion: "",
      membersData: {
        data: [],
        meta: {
          total_count: 0,
          current_page: 0,
          per_page: 0,
          total_pages: 0
        }
      },
      clearFilters: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
         if(apiRequestCallId === this.getMemberInfo){
          this.setState({
            membersData: responseJson
          })     
        }
      }
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(){
    this.getMemberInfoApiCall()
  }
  setLanguage = (state: string) => {
    this.setState({ selectedLanguage: state })
  }

  setCountry = (state: string) => {
    this.setState({ selectedCountry: state })
  }

  setGivenState = (state: string) => {
    this.setState({ selectedState: state })
  }

  setCity = (state: string) => {
    this.setState({ selectedCity: state })
  }

  setRegion= (state: string) => {
    this.setState({ selectedRegion: state })
  }

  clearAllFilters = ()=>{
    this.setState({selectedCity: '', 
      selectedLanguage: '',
      selectedCountry: '',
      selectedRegion: '',
      selectedState: '',
    })
    this.setState(prevState =>({
      clearFilters: !prevState.clearFilters
    }))
  }

  goBackToMemberDirectory=()=>{
    this.props.navigation.goBack()
  }

  getMemberInfoApiCall = () => {

    const header = {
        "Content-Type": "application/json",
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMemberInfo = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'account/accounts'
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getMemberInfo;
};
  // Customizable Area End
}
