Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiPutMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.getInterestAPiEndPoint = 'bx_block_interests/interests';
exports.btnExampleTitle = "CLICK ME";
exports.getCountryAPiEndPoint = 'accounts/country_code_and_flag';
exports.editProfileApiEndPoint = 'account/update';
exports.editPasswordApiEndPoint = 'account/change_password';
exports.getPBAconnectionsEndPoint= 'account/pba_connections';
exports.getUserInterestListApiEndPoint = 'bx_block_interests/impact_areas';
exports.getUserSkillListApiEndPoint = 'bx_block_interests/skills_and_approaches';
// Customizable Area End