import React from "react";
import { Typography, Box, CardMedia, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, Dialog, DialogContent } from '@material-ui/core';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import {t} from "i18next";
export const GroupMediaData = ({ groupMediaData, type }: any) => {
    const [open, setOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState('');

    const handleOpen = (url: string) => {
        setSelectedImage(url);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage('');
    };
    return (
        <Grid container spacing={3}>
            {groupMediaData && groupMediaData.length > 0 ? (
                groupMediaData.map((data: any) => (
                    data.attributes[type].length > 0 && (
                        data.attributes[type].map((item: any, index: any) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <div>
                                    {type === "images" && (
                                        <div>
                                            <CardMedia
                                                height="140"
                                                component="img"
                                                data-test-id="cardMedia"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    margin: '0 auto',
                                                    maxHeight: '200px',
                                                    objectFit: 'contain',
                                                    borderRadius: '5px'
                                                }}
                                                src={item.url}
                                                onClick={() => handleOpen(item.url)}
                                            />
                                            <IconButton
                                            data-test-id="iconButtonCardClick"
                                                onClick={() => handleOpen(item.url)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '10px',
                                                    right: '10px',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    color: 'white'
                                                }}
                                            >
                                                <ZoomOutMapIcon />
                                            </IconButton>

                                            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth data-test-id="dialogCard">
                                                <DialogContent style={{ padding: 0 }}>
                                                    <img
                                                        src={selectedImage}
                                                        alt="Enlarged view"
                                                        style={{ width: '100%', height: 'auto' }}
                                                    />
                                                </DialogContent>
                                            </Dialog>
                                        </div>
                                        
                                    )}
                                    {type === "videos" && (
                                        <video controls style={{ height: 'auto', width: '100%' }}>
                                            <source src={item.url} type="video/mp4" />
                                        </video>
                                    )}
                                    {type === "audios" && (
                                        <audio controls style={{ width: '100%' }}>
                                            <source src={item.url} type="audio/mpeg" />
                                        </audio>
                                    )}
                                    {type === "documents" && (
                                        <a target='_blank' href={item.url} style={{ display: "flex", textDecoration: "none" }}>
                                            <div className="doc-box" style={{ width: "100%" }}>
                                                <Box className='PostImageLefts'>
                                                    <svg
                                                        height="39"
                                                        width="30"
                                                        viewBox="0 0 90.48 122.88"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                                                    </svg>
                                                </Box>
                                            </div>
                                        </a>
                                    )}
                                    <div
                                        style={{
                                            backgroundColor: 'background.paper',
                                            width: '100%',
                                        }}
                                    >
                                        <List style={{ border: 'none' }}>
                                            <ListItem alignItems="flex-start" style={{ padding: '8px 0' }}>
                                                <ListItemAvatar>
                                                    <Avatar src={data.attributes.author_image} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <Typography
                                                                    component="span"
                                                                    data-test-id="mediaAuthor"
                                                                    variant="body2"
                                                                    style={{
                                                                        lineHeight: '22px',
                                                                        fontSize: '14px',
                                                                        display: 'inline',
                                                                        fontWeight: 700,
                                                                        color: '#475569',
                                                                        cursor: 'pointer',
                                                                        fontFamily: 'Comfortaa-Bold',
                                                                    }}
                                                                // onClick={() => { }}
                                                                >
                                                                    {data.attributes.author_name}
                                                                </Typography>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography
                                                                        color="textSecondary"
                                                                        variant="body2"
                                                                        style={{
                                                                            color: '#475569',
                                                                            fontSize: '12px',
                                                                            lineHeight: '18px',
                                                                            fontWeight: 400,
                                                                            fontFamily: 'Nunito-Regular',
                                                                        }}
                                                                    >
                                                                        {data.attributes.created_at}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                data-test-id="mediaDescription"
                                                                component="span"
                                                                variant="body2"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }} 
                                                                //    style={{
                                                                //        fontFamily: 'Nunito-Regular',
                                                                //        fontSize: '12px',
                                                                //        fontWeight: 400,
                                                                //        overflow: "hidden",
                                                                //        color: "var(--neutrals-cool-gray-600, #475569)",
                                                                //        textOverflow: "ellipsis",
                                                                //        whiteSpace: "nowrap",
                                                                //        fontStyle: "normal",
                                                                //        lineHeight: "18px"
                                                                //      }}
                                                                className="trending-desc"
                                                            // onClick={() => { }}
                                                            >
                                                                {data.attributes.description}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </div>
                                </div>
                            </Grid>
                        )))
                ))) : (
                <Grid item xs={12} >
                    <Typography className="not-found-text">{t(`No record found`)}</Typography>
                </Grid>
            )
            }
        </Grid>
    )
}