import React, { useState } from "react";
// Customizable Area Start
import { Button, Grid, Box, Typography, Badge, Avatar } from "@material-ui/core";
import { t } from "i18next";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import { user4 } from '../assets';
import { UserData } from "../MemberDirectoryController"

interface MemberProp {
    path: string
    name: string
    location: string
    mission: string
    affiliation: string[]
    skills: string[]
}

interface MemberDirectoryListProps {
    membersData: any,
    navigation: any
}

const MemberDirectoryList = (props: MemberDirectoryListProps) => {
    const navigateToMemberDetail = () => {
        props.navigation.navigate('MemberDetails')
    }

    const renderSkills = (skillsObject: any) => {
        const [showAll, setShowAll] = useState(false);
        const displayeSkills = showAll ? skillsObject.attributes.skill_name : skillsObject.attributes.skill_name.slice(0, 10);
        const remainingCount = skillsObject.attributes.skill_name.length - displayeSkills.length;
        return (
            <Grid container item className="skils_grid">
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                }}>
                    {displayeSkills.map((item: any) => {
                        return (<div style={{ marginInline: "5px", width: "max-content" }}><Button className="skills_button"
                            variant="contained"
                        >
                            <div className="tag-interest">{item.name}</div>
                        </Button></div>)
                    })}
                    {remainingCount > 0 && !showAll &&
                        <Button variant="text" onClick={(event: any) => {
                            event.stopPropagation()
                            setShowAll(true)
                        }}
                            className="md_show_less">{t('+')}{remainingCount} {t('more')}
                        </Button>}
                    {showAll &&
                        <Button variant="text" onClick={(event: any) => {
                            event.stopPropagation()
                            setShowAll(false)
                        }}
                            className="skills_show_less">{t('Show Less')}
                        </Button>}
                </div>
            </Grid>
        )
    }
    return (
        <div>
            {props.membersData.map((item: any, index: number) => {
                return (
                    <Box className="member_card_box" data-test-id={`member_card ${index}`}
                        onClick={navigateToMemberDetail}>
                        <Grid container spacing={1} className="member_card">
                            <Grid item container xs={12} sm={6} className="member_main_info">
                                <Grid container spacing={0} className="member_intro">
                                    <Grid className="member_avatar">
                                        <Avatar
                                            src={item.attributes.photo}
                                            alt="User Profile Picture"
                                            style={{
                                                width: 56,
                                                height: 56,
                                            }}
                                        />
                                    </Grid>
                                    <Grid className="member_name">
                                        <Typography
                                            variant="subtitle2"
                                            className="md_name"
                                        >{t(item.attributes.full_name)}</Typography>
                                        <Grid item className="md_location_info" >
                                            <Badge style={{ marginTop: '2px' }}>
                                                <PlaceOutlinedIcon
                                                    style={{
                                                        color: '#475569',
                                                        fontSize: 16
                                                    }}></PlaceOutlinedIcon>
                                            </Badge>
                                            <Typography
                                                variant="subtitle2"
                                                className="md_location_text"
                                            >{t(item.attributes.country)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '16px' }}>
                                    <Typography
                                        variant="subtitle2"
                                        className="mission_title"
                                    >{t("Mission / About")}</Typography>
                                    <Box
                                        className="mission_box"
                                    >
                                        <FiberManualRecordIcon fontSize="small"
                                            style={{
                                                color: '#475569',
                                                fontSize: "10px",
                                                marginRight: "8px",
                                                marginTop: '4px'
                                            }} />
                                        <Typography className="mission_description">{t(item.attributes.about_yourself)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid style={{ marginTop: '16px' }}>
                                    <Typography
                                        variant="subtitle2"
                                        className="mission_title"
                                    >{t("Organizational affiliations and Socials")}</Typography>
                                    <Grid container spacing={1}>
                                        {/* {item.affiliation.map((item: any, index: number) => ( */}
                                            <Grid item xs={12} sm={12} md={12} key={index}>
                                                <Box
                                                    className="oas_box"
                                                >
                                                    <FiberManualRecordIcon fontSize="small"
                                                        style={{
                                                            color: '#475569',
                                                            fontSize: "10px",
                                                            marginRight: "8px",
                                                            marginTop: '4px'
                                                        }} />
                                                    <Typography className="mission_description">{item.attributes.organizational_affilation}</Typography>
                                                </Box>
                                            </Grid>
                                        {/* // ))} */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sm={1}></Grid>
                            <Grid item container xs={12} sm={5} className="member_secondary_info">
                                <Typography
                                    variant="subtitle2"
                                    className="skills_text"
                                >{t("Skills")}</Typography>
                                {renderSkills(item)}
                            </Grid>
                        </Grid>
                    </Box>)
            })}
        </div>
    )
}

// Customizable Area End
export default MemberDirectoryList