import React from "react";
import { Box, Avatar, Typography, Button, Modal, Paper, TextField, TableContainer,Grid } from "@material-ui/core";
import CommentsController, { Props } from "./CommentsController";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from "moment";


const styles = {
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        width: '588px',
        height: '251px',
        outline: 'none',
        borderRadius: '8px',
    },
    heading: {
        maxWidth: '100%',
        height: 53,
        backgroundColor: '#5497FF',
        borderRadius: '8px 8px 0px 0px',
        fontSize: "16px",
        lineHeight: "22px",
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        color: 'white',
        bottom: '10px',
        paddingLeft: '23px',
        fontFamily: "Nunito-Regular"
    },
    avatar: {
        width: '48px',
        height: '48px',
        alignItems: 'start',
        marginBottom: '65px'
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '20px',
        gap: 16,
    },
    inputField: {
        hieght: 'fit',
        width: '100%',
    },
    actionButton: {
        minWidth: 120,
        height: '44px',
        width: '270',
        marginRight: '1rem',
    },
    cancelButton: {
        minWidth: 120,
        height: '44px',
        width: '270',
        marginRight: '1rem',
        backgroundColor: 'white',
        color: '#5497FF',
        borderRadius: 8,
        borderWidth: 2
    },
    buttonGroup: {
        display: 'flex',
        borderRadius: '8px',
        bottom: '8px',
        left: '8px',
        height: '60px',
        width: '588px'
    },
};

class Comments extends CommentsController {
    constructor(props: Props) {
        super(props);
    }

    renderChatUi = () => {
        if (!this.state.isChatOpen) {
            return (
                <Box style={{ paddingTop: '0px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 600, lineHeight: '22px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                        Private Message
                    </Typography>
                    {this.state.commentUsers.map((user,index) => (
                        <>
                            <Box
                                key={user.id}
                                style={{
                                    margin: "24px 0px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    cursor: 'pointer'

                                }}
                                onClick={this.handleChatOpen.bind(this, user?.account?.data?.id)}
                            >
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                    <Avatar
                                        src={user?.account?.data?.attributes?.photo}
                                        alt={user.name || 'John Doe'}
                                        style={{ marginRight: "16px", height: '56px', width: '56px', marginTop: '2px' }}
                                    />
                                    <Grid container style={{marginBottom: 'auto', marginLeft: '4px'}}>
                                        <Grid item container style={{marginBottom: '4px'}}>
                                            <Typography style={{ fontSize: '16px', fontWeight: 600, lineHeight: '22px', color: '#475569', fontFamily: "Nunito-Regular" }}>{user?.account?.data?.attributes?.full_name}</Typography>
                                        </Grid>


                                        <Grid item container>
                                            <Typography variant="body1" style={{ fontSize: '14px', fontWeight: 400, lineHeight: '18px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                                                {user.last_comment.comment}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid style={{marginBottom: 'auto'}}>
                                    <Typography variant="body2" style={{ fontSize: '12px', fontWeight: 400, lineHeight: '18px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                                        {moment(user.last_comment.created_at).fromNow()}
                                    </Typography>
                                </Grid>
                            </Box>
                            {this.state?.commentUsers.length - 1 != index && <hr style={{ border: '1px solid #CBD5E1', width: '100%', margin:0 }}></hr>}
                        </>
                    ))}
                </Box>
            )

        } else {
            return (
                <Box style={{  display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ display: 'flex', alignItems:'center' }}>
                        <ArrowBackIcon style={{ cursor: "pointer" ,display: 'flex', marginRight: '10px', color: "#000000", alignItems: 'center' }} onClick={this.handleBack} />
                        <Typography variant="h6" style={{ display: "flex",fontSize: '18px', fontWeight: 600, lineHeight: '26px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                            Private Message
                        </Typography>
                        <Typography onClick={this.handleAddChat} 
                            style={{
                                padding: 0, 
                                justifyContent: 'end', 
                                marginLeft: 'auto', 
                                cursor: 'pointer' ,
                                color: '#5497FF',
                                fontSize: '16px', fontWeight: 600, lineHeight: '18px', fontFamily: "Nunito-Regular"
                            }} 
                                >Add</Typography>
                    </Box>
                    {this.state.commentHistoryData.map((user, index) => (
                        <>
                            <Box
                                key={user.id}
                                style={{
                                    margin: "24px 0px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                    <Avatar
                                        src={user?.attributes?.author_image}
                                        alt={user.name || 'John Doe'}
                                        style={{ marginRight: "16px", height: '56px', width: '56px' }}
                                    />
                                    <Box style={{marginBottom: 'auto'}}>
                                        <Box style={{marginBottom: '4px'}}>
                                            <Typography style={{fontSize: '16px', fontWeight: 600, lineHeight: '22px', color: '#475569', fontFamily: "Nunito-Regular"}}>{user?.attributes?.author_name}</Typography>
                                        </Box>

                                        <Box>
                                            <Typography style={{fontSize: '14px', fontWeight: 400, lineHeight: '18px', color: '#475569', fontFamily: "Nunito-Regular"}}>
                                                {user?.attributes?.comment}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ marginBottom: 'auto' }}>
                                    <Typography style={{ fontSize: '12px', fontWeight: 400, lineHeight: '18px', color: '#475569', fontFamily: "Nunito-Regular" }}>
                                        {user.attributes?.created_at}
                                    </Typography>
                                </Box>
                            </Box>
                            {this.state?.commentHistoryData.length - 1 != index && <hr style={{ border: '1px solid #CBD5E1', width: '100%', margin:0 }}></hr>}
                        </>
                    ))}
                    <Modal open={this.state.isOpenAddChat} style={styles.modal}>

                        <Paper style={styles.paper}>
                            <Box>
                                <Typography style={styles.heading}>Private Message</Typography>
                            </Box>

                            <Box style={styles.dialogContent} textAlign={"center"}>
                                <Avatar
                                    src ={this.state.userInfo.photo}
                                    style={{height: '48px', width: '48px', marginBottom: 'auto'}}
                                />
                                <TextField
                                    style={styles.inputField}
                                    placeholder="Hey, share what's on your mind..."
                                    multiline
                                    minRows={4}
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                />
                            </Box>
                            <Box
                                style={styles.buttonGroup}
                                justifyContent={"center"}
                                position={"relative"}
                            >
                                <Button
                                    onClick={this.handleClose}
                                    variant="outlined"
                                    color="primary"
                                    style={styles.cancelButton}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    onClick={this.handleSend.bind(this)}
                                    variant="contained"
                                    color="primary"
                                    style={styles.actionButton}
                                >
                                    SEND
                                </Button>
                            </Box>
                        </Paper>
                    </Modal>
                </Box >
            )
        }
    }

    render() {
        return (
            <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
                <Paper elevation={3} style={{
                    borderRadius: '8px',
                    gap: '20px',
                    padding: '24px'
                }} >
                    {this.renderChatUi()}
                </Paper>
            </TableContainer>
        );
    }
}


export default Comments
