import React, { useRef } from "react";
import {
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Typography,
    Button,
    Avatar,
    IconButton
} from "@material-ui/core";
import { t } from "i18next"
import CustomAutocomplete from "./CustomAutoComplete.web";
import CustomChips from "./CustomChips.web";
import RenderInput from "./RenderInput.web";
import CustomEditor from "./CustomEditor.web";
import MultipleSelectAutoComplete from "./MultipleSelectAutoComplete.web";
import EditIconOutlined from '@material-ui/icons/EditOutlined';
import CountryAutoComplete from "./CountryAutoComplete.web";
import StateAutoComplete from "./StateAutoComplete.web";
import { showError } from "./ShowError.web";
import MobileNumberInput from "./MobileInputRenderer.web";

const ProfileSetting = ({ handlePhoneNumberChange, handleConnectionList, handleAddToUserSkill, handleAddNewSkill, handleAddExistingSkill, handleDeleteExistingSkill, handleAddToUserInterest, handleDeleteExistingInterest, handleAddExistingInterest, handleAddNewInterest, handleDropdownChange, handleImageSelect, state, deleteInterestsData, deleteSkillsData, handleChange, handleSubmit, handleSelectInterest, handleShowFields, handleAddFormFields, handleOtherFields, handleRemoveAddMoreFields, handleOASText, handleSEText }: any) => {
    const data = {
        countries: [
            { title: 'United States of America' },
            { title: 'India' },
            { title: 'UK' },
            { title: 'Austrlia' },
            { title: 'Iraq' },
            { title: 'UAE' },
        ],
        states: [
            { title: "Alabama" },
            { title: "Alaska" },
            { title: "Arizona" },
            { title: "Arkansas" },
            { title: "California" },
            { title: "Delhi" },
        ],
        languages: [
            { title: "English" },
            { title: "Spanish" },
            { title: "Mandarin Chinese" },
            { title: "Hindi" },
            { title: "Arabic" },
        ],
        cities: [
            { title: "Miami" },
            { title: "Orlando" },
            { title: "Tampa" },
            { title: "Jacksonville" },
            { title: "St. Petersburg" },
        ],
        timezones: [
            { title: "Eastern Standard Time (EST)" },
            { title: "Eastern Daylight Time (EDT)" },
            { title: "Central Standard Time (CST)" },
        ],
    }
    const fileInputRef = useRef<any>(null);
    const triggerFileInput = () => {
        fileInputRef.current?.click();
    };

    return (
        <>
            <div>
                <>
                    <div className="upload_photo_main_container">
                        <Grid container
                            className="upload_photo_container"
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {!state.ProfileFormField.photo && !state.imagePreview && <>
                                <div data-test-id='triggerInput' onClick={triggerFileInput} style={{ justifyContent: 'center', display: 'flex' }}>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5 15V18H6.5V15H4.5V18C4.5 19.1 5.4 20 6.5 20H18.5C19.6 20 20.5 19.1 20.5 18V15H18.5ZM7.5 9L8.91 10.41L11.5 7.83V16H13.5V7.83L16.09 10.41L17.5 9L12.5 4L7.5 9Z" fill="#64748B" />
                                    </svg>
                                    <input
                                        className="input-hide-image"
                                        data-test-id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageSelect}
                                        ref={fileInputRef}
                                    />
                                </div>
                                <Typography
                                    variant="subtitle2"
                                    className="upload_photo_text"
                                >{t("Upload Photo")}</Typography>
                            </>
                            }
                            {(state.imagePreview || state.ProfileFormField.photo) && <div style={{ display: 'row' }}>
                                <Avatar
                                    src={state.imagePreview || state.ProfileFormField.photo}
                                    alt="User Profile Picture"
                                    style={{
                                        width: 120,
                                        height: 120,
                                        cursor: 'none'
                                    }}
                                />
                                <div className="myDIV">
                                    <input
                                        className="input-hide-image"
                                        data-test-id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageSelect}
                                        ref={fileInputRef}
                                    />
                                    <IconButton
                                        aria-label="Edit Profile"
                                        style={{
                                            zIndex: 100,
                                            borderRadius: '20px',
                                            height: '24px',
                                            backgroundColor: '#fff',
                                            width: '24px',
                                            marginLeft: '97px',
                                            marginTop: '-35px',
                                        }}
                                        onClick={triggerFileInput}
                                    >
                                        <EditIconOutlined className="edit-profile" />
                                    </IconButton>
                                </div>
                            </div>
                            }
                        </Grid>
                    </div>
                    <Grid className="upload_photo_limit_container">
                        <Typography
                            variant="subtitle2"
                            style={{
                                fontFamily: "Nunito-Regular",
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                color: state.imageError ? '#F97171' : '#475569'
                            }}
                        >
                            {t("Upload jpg/png upto 10mb")}
                        </Typography>
                    </Grid>
                </>
                <form style={{ marginTop: '16px' }}>
                    <Grid container spacing={3}>
                        <RenderInput
                            title={'Full Name*'}
                            initialValue={state.ProfileFormField.full_name}
                            name={'full_name'}
                            mobile={false}
                            onChange={handleChange}
                            required={true} errorKey="nameError"
                            profileErrorField={state.ProfileErrorField} />
                        <RenderInput
                            title={'Email Address*'}
                            initialValue={state.ProfileFormField.email}
                            name={'email'}
                            mobile={false}
                            onChange={handleChange}
                            required={true}
                            errorKey="emailError"
                            profileErrorField={state.ProfileErrorField} />
                        <MobileNumberInput
                            profileErrorField={state.ProfileErrorField}
                            state={state}
                            handlePhoneNumberChange={handlePhoneNumberChange}
                            onChange={handleChange}
                        />
                        <CountryAutoComplete
                            title={'Country*'}
                            initialValue={{
                                attributes: {
                                    name: state.ProfileFormField.country
                                }
                            }}
                            options={state.countryList}
                            onChange={handleDropdownChange}
                            fieldName={'country'}
                            profileErrorField={state.ProfileErrorField}
                            errorKey="countryError"
                        />
                        <StateAutoComplete title={'State*'}
                            initialValue={{ name: state.ProfileFormField.state }}
                            options={state.stateList}
                            onChange={handleDropdownChange}
                            fieldName={'state'}
                            profileErrorField={state.ProfileErrorField}
                            errorKey="stateError"
                        />
                        <StateAutoComplete title={'City*'}
                            initialValue={{ name: state.ProfileFormField.city }}
                            options={state.cityList}
                            onChange={handleDropdownChange}
                            fieldName={'city'}
                            profileErrorField={state.ProfileErrorField}
                            errorKey="cityError"
                        />
                        <CustomAutocomplete title={'Select Time Zone*'}
                            initialValue={{ title: state.ProfileFormField.time_zone }}
                            options={data.timezones}
                            onChange={handleDropdownChange}
                            fieldName={'time_zone'}
                            profileErrorField={state.ProfileErrorField}
                            errorKey="zoneError"
                        />
                        < Grid item xs={12} lg={6} sm={12} md={6} >
                            <FormControl fullWidth>
                                <FormLabel className="dropdown_title">
                                    {t('PBA Connection (If Any)')}
                                </FormLabel>
                                <MultipleSelectAutoComplete
                                    options={state.pbaConnectionsList}
                                    value={state.ProfileFormField.connections}
                                    handleConnectionList={handleConnectionList}
                                />
                            </FormControl>
                        </Grid>
                        <CustomAutocomplete title={'Select Working Language*'}
                            initialValue={{ title: state.ProfileFormField.working_language }}
                            options={data.languages}
                            onChange={handleDropdownChange}
                            fieldName={'working_language'}
                            profileErrorField={state.ProfileErrorField}
                            errorKey="workingLanguageError"
                        />
                        <CustomAutocomplete title={'Select App Preferred Language*'}
                            initialValue={{ title: state.ProfileFormField.preferred_language }}
                            options={[{ title: 'English' }, { title: 'French' }, { title: 'Bengali' }]}
                            onChange={handleDropdownChange}
                            fieldName={'preferred_language'}
                            errorKey="languageError"
                            profileErrorField={state.ProfileErrorField}
                        />
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel className="dropdown_title">
                                    {t('Intrested In')}
                                </FormLabel>
                                <CustomChips
                                    title='Add New Interest'
                                    buttonText='Add another interest'
                                    placeHolderText="Type your interest here"
                                    modalText="Add another interest"
                                    data={state.ProfileFormField.interest_name}
                                    handleAddNewInterest={handleAddNewInterest}
                                    list={state.staticInterestList}
                                    selectedList={state.staticInterestSelectedList}
                                    handleDelete={deleteInterestsData}
                                    addToUserList={handleAddToUserInterest}
                                    handleDeleteExistingInterest={handleDeleteExistingInterest}
                                    handleAddExistingInterest={handleAddExistingInterest}
                                ></CustomChips>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel className="dropdown_title">
                                    {t('Skills*')}
                                </FormLabel>
                                <CustomChips title='Add New Skills'
                                    buttonText='Add another skill'
                                    placeHolderText="Type your skill here"
                                    modalText="Add another Skills"
                                    data={state.ProfileFormField.skill_name}
                                    handleAddNewInterest={handleAddNewSkill}
                                    list={state.staticSkillList}
                                    selectedList={state.staticSkillSelectedList}
                                    handleDelete={deleteSkillsData}
                                    addToUserList={handleAddToUserSkill}
                                    handleDeleteExistingInterest={handleDeleteExistingSkill}
                                    handleAddExistingInterest={handleAddExistingSkill}
                                ></CustomChips>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel className="textinput_title">
                                    {t('About*')}
                                </FormLabel>
                                <TextField
                                    multiline
                                    minRows={4}
                                    value={state.ProfileFormField.about_yourself}
                                    onChange={handleChange}
                                    type={'text'}
                                    placeholder={``}
                                    className="about-box_here"
                                    variant="outlined"
                                    name={'about_yourself'}
                                    InputProps={{
                                        style: {
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            lineHeight: '22px',
                                            color: '#1E293B'
                                        },
                                    }}
                                />
                                {showError({
                                    errorKey: 'aboutError',
                                    profileErrorField: state.ProfileErrorField            
                                })}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel className="textinput_title">
                                    {t('Organizational Affiliations and Socials*')}
                                </FormLabel>
                                <CustomEditor
                                    item={state.ProfileFormField.organizational_affilation}
                                    handleChange={handleOASText}
                                    errorKey="affiliationsError"
                                    profileErrorField={state.ProfileErrorField} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel className="textinput_title">
                                    {t('Summary of Experience*')}
                                </FormLabel>
                                <CustomEditor
                                    item={state.ProfileFormField.experience}
                                    handleChange={handleSEText}
                                    errorKey="experienceError"
                                    profileErrorField={state.ProfileErrorField}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item className="btn-box" xs={12} lg={12} sm={12} md={12}>
                            <Button
                                type="button"
                                style={{
                                    fontFamily: "Nunito-Regular",
                                    fontSize: '16px',
                                    color: '#FFFFFF',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    boxShadow: 'none'
                                }}
                                onClick={handleSubmit}
                                className="setting-submit-btn"
                                variant="contained"
                                color="primary">{t('SAVE')}</Button>
                        </Grid>
                    </Grid>
                </form>

            </div >
        </>

    );
};

export default ProfileSetting;